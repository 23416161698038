var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isAllSelected)?_c('VBtn',{staticClass:"subtitle-2 elevation-0 px-2 border-1 default-cursor",attrs:{"height":"32"}},[_vm._v(" "+_vm._s(`${_vm.isTotalTitle} ${_vm.items.length}`)+" ")]):_c('div',{staticClass:"d-flex flex-wrap"},[_vm._l((_vm.items),function(item,index){return (index < _vm.max)?_c('div',{key:index,staticClass:"chip subtitle-2 d-flex py-1 br-4 w-fit",class:_vm.outlined ? 'border-1 px-2': 'pl-3 pr-2',style:(`
        background-color: ${ _vm.outlined ? '#F5F5F5' : _vm.color };
        max-width: ${_vm.maxWidth}px;
      `)},[_c('div',{class:[
          {'text-truncate' : _vm.truncate},
          {'text-no-wrap': _vm.outlined},
        ]},[_vm._v(" "+_vm._s(item.title || item)+" ")]),(!_vm.outlined && _vm.hasRemove)?_c('VBtn',{staticClass:"ml-1",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.remove(index, item.id)}}},[_c('VIcon',{attrs:{"color":_vm.iconColor},domProps:{"textContent":_vm._s('mdi-close')}})],1):_vm._e()],1):_vm._e()}),(_vm.items.length > _vm.max)?_c('VMenu',{attrs:{"content-class":"br-8","min-width":"158","offset-y":"","open-on-hover":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('VBtn',_vm._g(_vm._b({staticClass:"chip subtitle-2 elevation-0 px-1",class:_vm.outlined ? 'border-1' : 'red--text',attrs:{"height":_vm.outlined ? 32 : 30,"min-width":"32"}},'VBtn',attrs,false),on),[_vm._v(" +"+_vm._s(_vm.items.length - _vm.max)+" ")])]}}],null,false,2621403800)},[_c('VList',{staticClass:"overflow-y-auto py-2",attrs:{"max-height":"383"}},_vm._l((_vm.plusBtnItems),function(item,index){return _c('VListItem',{key:index,staticClass:"list-item_min-height",attrs:{"dense":""}},[_c('VListItemTitle',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(item.title || item)+" ")])],1)}),1)],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }