<template>
  <div>
    <VBtn
      v-if="isAllSelected"
      class="subtitle-2 elevation-0 px-2 border-1 default-cursor"
      height="32"
    >
      {{ `${isTotalTitle} ${items.length}` }}
    </VBtn>
    <div v-else class="d-flex flex-wrap">
      <div
        v-for="(item, index) in items"
        v-if="index < max"
        :key="index"
        class="chip subtitle-2 d-flex py-1 br-4 w-fit"
        :class="outlined ? 'border-1 px-2': 'pl-3 pr-2'"
        :style="`
          background-color: ${ outlined ? '#F5F5F5' : color };
          max-width: ${maxWidth}px;
        `"
      >
        <div
          :class="[
            {'text-truncate' : truncate},
            {'text-no-wrap': outlined},
          ]"
        >
          {{ item.title || item }}
        </div>
        <VBtn
          v-if="!outlined && hasRemove"
          class="ml-1"
          icon
          x-small
          @click="remove(index, item.id)"
        >
          <VIcon
            :color="iconColor"
            v-text="'mdi-close'"
          />
        </VBtn>
      </div>
      <VMenu
        v-if="items.length > max"
        content-class="br-8"
        min-width="158"
        offset-y
        open-on-hover
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <VBtn
            v-bind="attrs"
            class="chip subtitle-2 elevation-0 px-1"
            :class="outlined ? 'border-1' : 'red--text'"
            :height="outlined ? 32 : 30"
            min-width="32"
            v-on="on"
          >
            +{{ items.length - max }}
          </VBtn>
        </template>

        <VList
          class="overflow-y-auto py-2"
          max-height="383"
        >
          <VListItem
            v-for="(item, index) in plusBtnItems"
            :key="index"
            class="list-item_min-height"
            dense
          >
            <VListItemTitle class="subtitle-2">
              {{ item.title || item }}
            </VListItemTitle>
          </VListItem>
        </VList>
      </VMenu>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    max: {
      type: Number,
      default: 1000,
    },
    maxWidth: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'default',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    totalItems: {
      type: Number,
      default: 1000,
    },
    isTotalTitle: {
      type: String,
      default: 'Все города',
    },
    backColor: {
      type: String,
      default: '#F5F5F5',
    },
    hasRemove: {
      type: Boolean,
      default: true,
    },
    truncate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['remove', 'removeById'],
  computed: {
    isAllSelected() {
      return this.totalItems > 0 ? this.items.length === this.totalItems : false;
    },
    plusBtnItems() {
      return this.items.filter((item, index) => index >= this.max);
    },
    color() {
      const colors = {
        default: this.backColor,
        success: '#E5FFEA',
        error: '#FFF1EE',
      };
      return colors[this.type];
    },
    iconColor() {
      const iconColors = {
        default: 'grey',
        success: 'green',
        error: 'orange darken-3',
      };
      return iconColors[this.type];
    },
  },
  methods: {
    remove(index, id) {
      this.$emit('remove', index);
      this.$emit('removeById', id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

.chip {
  margin: 4px 4px 4px 0;
}
</style>
